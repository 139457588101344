import React, { createContext, useContext, useState } from "react";

const UserContext: any = createContext<any>(null);

export default function AuthContextProvider({ children }: any) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [location, setLocation] = useState<number>(0);
  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        location,
        setLocation,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const UserAuth = () => {
  return useContext(UserContext);
};
