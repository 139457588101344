import { Fragment, useState } from "react";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import { CiPizza } from "react-icons/ci";



function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function NewOrder({ mem, setMem, userNavigation }: any) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-[999]" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <CiPizza
                        className="h-6 w-6 text-ben"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="font-semibold leading-6 text-gray-900 text-xl font-cub tracking"
                      >
                        Select a location
                      </Dialog.Title>
                      <div className="mt-2">
                        <fieldset aria-label="Choose a memory option">
                          <div className="flex items-center justify-between">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Locations
                            </div>
                          </div>

                          <RadioGroup
                            value={mem}
                            onChange={setMem}
                            className="mt-2 space-y-4"
                          >
                            {userNavigation?.map((option: any) => (
                              <RadioGroup.Option
                                key={option.name}
                                value={option}
                                disabled={!option.inStock}
                                className={({ active, checked }) =>
                                  classNames(
                                    option.inStock
                                      ? "cursor-pointer focus:outline-none"
                                      : "cursor-not-allowed opacity-25",
                                    active ? "ring-2 ring-green-500" : "",
                                    checked
                                      ? "bg-green-600 border-transparent text-white shadow-sm"
                                      : "bg-white border-gray-300 text-gray-900",
                                    "flex items-center justify-center rounded-md  px-3 py-3 text-sm font-semibold uppercase text-gray-900 ring-1 ring-gray-300  "
                                  )
                                }
                              >
                                {option.name}
                              </RadioGroup.Option>
                            ))}
                          </RadioGroup>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-ben px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ben"
                      onClick={() => setOpen(false)}
                    >
                      Set Location
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
