import { ArrowRightIcon } from "@heroicons/react/24/outline";
import logo from "../assets/logo.png";

export default function OrderOnline({ branch }: any) {
  console.log(branch);

  const handleGoToOrder = (item: any) => {
    //target blank opens the link in a new tab
    if (item === "Slice") {
      if (branch === "Chickasha") {
        window.open("https://www.benjayspizzeria.com/", "_blank");
      } else if (branch === "Lawton") {
        window.open("https://www.benjayslawton.com/", "_blank");
      } else {
        window.open("https://www.benjaysexpress.com/", "_blank");
      }
    } else if (item === "DoorDash") {
      window.open(
        "https://www.doordash.com/en-US/store/ben-&-jays-pizzeria-chickasha-24814984/",
        "_blank"
      );
    } else {
      window.open(
        "https://online.skytab.com/208b0af0b415d4d36a81444d59404744",
        "_blank"
      );
    }
  };
  return (
    <div className="max-w-7xl mx-auto grid sm:grid-cols-2 grid-cols-1 gap-8 pb-36">
      <div className="text-center col-span-full">
        <h1 className="text-9xl new-font uppercase text-white">
          Order <span className="text-ben">Online</span>
        </h1>
        <p className="font-cub tracking-widest">
          Order online from one of our online services
        </p>
      </div>
      {branch !== "Lawton" && (
        <div className="p-4 bg-zinc-800 rounded-lg border border-blue-500 text-center col-span-full">
          <img
            src={logo}
            alt="DoorDash"
            className="sm:h-12 h-8 w-auto mx-auto"
          />

          <h1 className="xl:text-8xl text-4xl text-blue-500 new-font uppercase tracking-widest mt-4">
            Order Now
          </h1>
          <p>Get your favorite meals delivered to your door</p>
          <button
            className="bg-blue-500 text-white p-2 rounded-lg w-full mt-8 font-cub tracking-wider"
            onClick={() => handleGoToOrder("benjays")}
          >
            Order Now <ArrowRightIcon className="h-6 w-6 inline ml-3" />
          </button>
        </div>
      )}

      <div className="p-4 bg-zinc-800 rounded-lg border border-yellow-400 text-center">
        <img
          src="https://images.squarespace-cdn.com/content/v1/608324e4b63c5e7e1b49aedf/4b87281c-8610-48e1-8f67-b3b3f20b2567/Slice-Logo-Screen_Yellow-BlackType.png"
          alt="Slice"
          className="h-12 w-auto mx-auto"
        />
        <h1 className="xl:text-8xl text-4xl new-font uppercase tracking-widest text-yellow-400 mt-4">
          Slice
        </h1>
        <p>Order from your favorite local pizzerias easy as pie</p>
        <button
          className="bg-yellow-400 text-zinc-950 p-2 rounded-lg w-full mt-8 font-cub tracking-wider"
          onClick={() => handleGoToOrder("Slice")}
        >
          Order Now <ArrowRightIcon className="h-6 w-6 inline ml-3" />
        </button>
      </div>
      <div className="p-4 bg-zinc-800 rounded-lg border border-ben text-center">
        <img
          src="https://1000logos.net/wp-content/uploads/2021/06/DoorDash-logo.png"
          alt="DoorDash"
          className="sm:h-12 h-8 w-auto mx-auto"
        />

        <h1 className="xl:text-8xl text-4xl text-ben new-font uppercase tracking-widest mt-4">
          DoorDash
        </h1>
        <p>Get your favorite meals delivered to your door</p>
        <button
          className="bg-ben text-white p-2 rounded-lg w-full mt-8 font-cub tracking-wider"
          onClick={() => handleGoToOrder("DoorDash")}
        >
          Order Now <ArrowRightIcon className="h-6 w-6 inline ml-3" />
        </button>
      </div>
    </div>
  );
}
