// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import meaty from "../assets/pizzas/meaty.png";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import texture from "../assets/texture.jpeg";
import salad from "../assets/salads/benandjays.png";
import fries from "../assets/appetizers/cheesefries.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function SwiperMenu() {
  return (
    <div className="py-12 relative isolate overflow-hidden mt-12 px-8">
      <img
        src={texture}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover"
      />
      <div className="hidden md:block">

      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={50}
        slidesPerView={3}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper: any) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        <SwiperSlide>
          {" "}
          <div className="rounded-2xl bg-zinc-900 shadow relative mt-24 px-8">
            <img
              className="inline-block h-auto sm:w-64 w-24  object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
              src={salad}
              alt=""
            />
            <div className="px-4 pt-24 md:pt-44 pb-8">
              <div className="text-ben new-font text-center text-xl xl:text-6xl uppercase mt-4">
                <span className="text-white">Delicious</span> Salads
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="rounded-2xl bg-zinc-900 shadow relative mt-24 px-8">
            <img
              className="inline-block h-auto sm:w-64 w-24   object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
              src={meaty}
              alt=""
            />
            <div className="px-4 pt-24 md:pt-44 pb-8">
              <div className="text-ben new-font text-center text-xl xl:text-6xl uppercase mt-4">
                <span className="text-white">Handmade</span> Pizzas
              </div>
            </div>
          </div>
        </SwiperSlide>
       
        <SwiperSlide>
        <div className="rounded-2xl bg-zinc-900 shadow relative mt-24 px-8">
            <img
              className="inline-block h-auto sm:w-64 w-24  flex-shrink object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
              src={fries}
              alt=""
            />
            <div className="px-4 pt-24 md:pt-44 pb-8">
              <div className="text-ben new-font text-center text-xl xl:text-6xl uppercase mt-4">
                <span className="text-white">Tasty</span> Appetizers
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      </div>
      <div className="block md:hidden">
      <div className="rounded-2xl bg-zinc-900 shadow relative mt-24 px-8">
            <img
              className="inline-block h-auto w-64 object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
              src={salad}
              alt=""
            />
            <div className="px-4 pt-44 pb-8">
              <div className="text-ben new-font text-center text-xl xl:text-6xl uppercase mt-4">
                <span className="text-white">Delicious</span> Salads
              </div>
            </div>
          </div>
          <div className="rounded-2xl bg-zinc-900 shadow relative mt-36 px-8">
            <img
              className="inline-block h-auto w-64 object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
              src={meaty}
              alt=""
            />
            <div className="px-4 pt-44 pb-8">
              <div className="text-ben new-font text-center text-xl xl:text-6xl uppercase mt-4">
                <span className="text-white">Handmade</span> Pizzas
              </div>
            </div>
          </div>
          <div className="rounded-2xl bg-zinc-900 shadow relative mt-36 px-8">
            <img
              className="inline-block h-auto w-64  flex-shrink object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
              src={fries}
              alt=""
            />
            <div className="px-4 pt-44 pb-8">
              <div className="text-ben new-font text-center text-xl xl:text-6xl uppercase mt-4">
                <span className="text-white">Tasty</span> Appetizers
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}
