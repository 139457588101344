import { Link } from "react-router-dom";
import quality from "../assets/pledge.png";

export default function Quality() {
  return (
    <div>
      <div className="relative isolate overflow-hidden ">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <p className="text-2xl mt-2 new-font uppercase">
                  Our Pledge to Quality
                </p>
                <h1 className="text-4xl tracking-wide new-font uppercase sm:text-8xl">
                  Best <span className="text-ben">Pizza</span>
                </h1>
                <p className="text-8xl leading-10 mt-2 new-font uppercase">
                  In Town!
                </p>
                <p className="mt-6 text-lg leading-8 text-white">
                    We are committed to providing the best pizza in town. Our
                    ingredients are fresh, our dough is made in-house, and our
                    pizzas are made to order. We take pride in our food and are
                    dedicated to providing the best experience for our customers.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="https://www.benjayspizzeria.com/"
                    className="rounded-2xl bg-ben w-full flex items-center justify-center py-2.5 text-md  text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 uppercase new-font tracking-widest"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Order Now
                  </a>
                  <Link
                    to="/menu"
                    className="text-md uppercase tracking-widest new-font leading-6 text-white w-full"
                  >
                    See the menu{" "}
                    <span aria-hidden="true" className="ml-2">
                      →
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <img
              src={quality}
              alt=""
              className="w-full  rounded-2xl object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
