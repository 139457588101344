import { Link } from "react-router-dom";
import pizza from "../assets/pizza.png";
import family from "../assets/locations/family.jpg";
import { useEffect, useState } from "react";
export default function Hero({branch}: any) {
  const [linkToOrder, setLinkToOrder] = useState("https://www.benjayspizzeria.com/");

  useEffect(() => {
    if (branch === "Chickasha") {
      setLinkToOrder("https://www.benjayspizzeria.com/");
    } else if (branch === "Lawton") {
      setLinkToOrder("https://www.benjayslawton.com/");
    } else {
      setLinkToOrder("https://www.benjaysexpress.com/");
    }
  }, [branch]);


  return (
    <div className="relative isolate overflow-hidden pt-14">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
          <h1 className="max-w-2xl text-4xl text-white  sm:text-8xl lg:col-span-2 xl:col-auto uppercase new-font">
            <span className="text-ben"> Family Owned </span>
            <span className="block">and opperated</span>
          </h1>
          <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
            <p className="text-xl leading-8 text-zinc-100  tracking-widest font-veneer">
              At Ben & Jays, our goal is to provide quality food, great service, and a family friendly atmosphere. We are a family owned and operated pizzeria that has been serving
              the <span className="text-ben">Chickasha and Lawton</span> area since 2022.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href={linkToOrder}
                className="rounded-2xl bg-ben w-full flex items-center justify-center py-2.5 text-md  text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 uppercase new-font tracking-widest"
                target="_blank"
                rel="noreferrer"
              >
                Order Now
              </a>
              <Link
                to="/menu"
                className="text-md uppercase tracking-widest new-font leading-6 text-white w-full"
              >
                See the menu{" "}
                <span aria-hidden="true" className="ml-2">
                  →
                </span>
              </Link>
            </div>
          </div>
          <img
            src={family}
            alt=""
            className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-12"
          />
        </div>
      </div>
    </div>
  );
}
