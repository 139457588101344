import React from 'react'

export default function ComingSoon() {
  return (
    <div className='min-h-screen h-full flex items-center justify-center'>
    <div className='-mt-96 text-center '>
        <h1 className='text-9xl new-font uppercase text-white'>Coming <span className='text-ben'>Soon</span></h1>
        <p className='font-cub tracking-widest'>We are working on this page</p>
    </div>
    </div>
  )
}
