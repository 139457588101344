import chickasha from "../assets/locations/chickasha.jpg";
import xpress from "../assets/locations/express.jpg";
import lawton from "../assets/locations/lawton.jpg";
import family from "../assets/locations/family.jpg";

export default function AboutUs() {
  return (
    <div className="px-4">
      <div className="text-center">
        <div className="text-4xl sm:text-9xl uppercase new-font">
          <span className="text-ben">BEN & JAY'S</span> PIZZERIA
        </div>
        <div className="lg:flex lg:items-center justify-between lg:gap-8 mt-8">
        <img src={family} alt="family" className="sm:w-auto w-full h-full mx-auto object-cover object-center sm:h-96 rounded-lg " />
        <div className="space-y-4 text-left lg:mt-0 mt-8">
          <p className="text-xl leading-8 text-zinc-100 uppercase tracking-widest font-veneer">
            We are a family owned and operated pizzeria that has been serving the Chickasha and Lawton area since 2022.
          </p>
          <p className="text-xl leading-8 text-zinc-100 uppercase tracking-widest font-veneer">
            Support local businesses by eating local. Enjoy the best pizza in
            Chickasha and Lawton like the locals do.
          </p>{" "}
          <p className="text-xl leading-8 text-zinc-100 uppercase tracking-widest font-veneer">
            Chickasha and Lawton residents have made Ben & Jay's Pizzeria one of
            the most popular pizzerias in the area. Maybe it will become a
            favorite of yours, too!
          </p>{" "}
          <p className="text-xl leading-8 text-zinc-100 uppercase tracking-widest font-veneer">
            Try some of the top dishes, and maybe you'll discover a new
            favorite.
          </p>{" "}
          <p className="text-xl leading-8 text-zinc-100 uppercase tracking-widest font-veneer">
            {" "}
            Why not add an order of basket of mozzarella sticks to your meal?
            This classic treat turns any meal into a fest.{" "}
          </p>{" "}
          <p className="text-xl leading-8 text-zinc-100 uppercase tracking-widest font-veneer">
            Partnered with Slice since 2022, this restaurant has been a staple
            of the community for years.
          </p>{" "}
          <p className="text-xl leading-8 text-zinc-100 uppercase tracking-widest font-veneer">
            {" "}
            Try the food and see why they've remained popular with locals. Make
            takeout easy with curbside pickup. Pay by credit card.
          </p>
        </div>
        </div>
      </div>
      <div className="hidden sm:flex sm:items-center sm:gap-4 mt-12 sm:space-y-0 space-y-4">
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 rounded-lg w-full">
          <img
            src={chickasha}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="absolute -top-10 -z-10 sm:mr-10 block transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div className="aspect-[1097/845] w-[68.5625rem] bg-zinc-900 opacity-70" />
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-4xl uppercase tracking-wide text-white sm:text-5xl new-font">
                Chickasha
              </h2>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                405-320-5018
              </p>
              <p className="mt-6 text-4xl new-font  leading-8 text-white drop-shadow-sm ">
                208 W CHICKASHA AVE
              </p>
              <p className=" text-4xl new-font  leading-8 text-white drop-shadow-sm ">
                CHICKASHA, OK 73018
              </p>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                10:45 AM - 9:00 PM
              </p>
            </div>
          </div>
        </div>
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 rounded-lg w-full">
          <img
            src={xpress}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="absolute -top-10 -z-10 sm:mr-10 block transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div className="aspect-[1097/845] w-[68.5625rem] bg-zinc-900 opacity-70" />
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-4xl uppercase tracking-wide text-white sm:text-5xl new-font">
                Xpress - Chickasha
              </h2>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                405-222-7111
              </p>
              <p className="mt-6 text-4xl new-font  leading-8 text-white drop-shadow-sm ">
              1000 W Choctaw Ave Ste 8
              </p>
              <p className=" text-4xl new-font  leading-8 text-white drop-shadow-sm ">
                CHICKASHA, OK 73018
              </p>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                11:00 AM - 9:00 PM
              </p>
            </div>
          </div>
        </div>
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 rounded-lg w-full">
          <img
            src={lawton}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="absolute -top-10 -z-10 sm:mr-10 block transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div className="aspect-[1097/845] w-[68.5625rem] bg-zinc-900 opacity-70" />
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-4xl uppercase tracking-wide text-white sm:text-5xl new-font">
                Lawton
              </h2>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                405-369-4718
              </p>
              <p className="mt-6 text-4xl new-font  leading-8 text-white drop-shadow-sm ">
                208 W CHICKASHA AVE
              </p>
              <p className=" text-4xl new-font  leading-8 text-white drop-shadow-sm ">
                CHICKASHA, OK 73018
              </p>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                10:45 AM - 9:00 PM
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
