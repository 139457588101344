import React from "react";
import menuItems from "../assets/json/menu.json";
import topping from "../assets/toppings/canadianbacon.png";
const tabs: any = [
  { name: "Specialty Pizzas", href: "#", current: true },
  { name: "Create Your Own'", href: "#", current: false },
  { name: "Appetizers", href: "#", current: false },
  { name: "Salads", href: "#", current: false },
  { name: "Fixins'", href: "#", current: false },
  { name: "Sammyz'", href: "#", current: false },
  { name: "Pasta'", href: "#", current: false },
  { name: "Beverages'", href: "#", current: false },

];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Menu() {
  const [specialtyPizza, setSpecialtyPizza] = React.useState<any[]>([]);
  const [appetizers, setAppetizers] = React.useState<any[]>([]);


  React.useEffect(() => {
    setSpecialtyPizza(menuItems?.pizzas);
    setAppetizers(menuItems?.appetizers);
  }, []);

  return (
    <div className="max-w-7xl mx-auto p-5">
      <div className="mt-12 sticky top-0 z-50 pb-8">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs.find((tab: any) => tab.current).name}
          >
            {tabs.map((tab: any) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav
            className="isolate flex divide-x divide-gray-200 rounded-lg shadow font-cub"
            aria-label="Tabs"
          >
            {tabs?.map((tab: any, tabIdx: any) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.current ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
      <h2 className="text-3xl font-cub mt-12">Specialty Pizzas</h2>
      <div className="grid grid-cols-3 gap-4 mt-2">
        {specialtyPizza?.map((pizza: any, index: any) => (
          <button
            key={index}
            type="button"
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-green-600 hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            <img src={topping} alt="topping" className="w-24 h-24 mx-auto" /> ``
            <h1 className="font-cub text-3xl">{pizza.name}</h1>
            <p className="mt-2">
              {pizza?.toppings?.map((topping: any) => (
                <span className="capitalize">{topping}, </span>
              ))}
            </p>
            <p>{pizza.price}</p>
          </button>
        ))}
      </div>
      <h2 className="text-3xl font-cub mt-8">Appetizers</h2>
      <div className="grid grid-cols-3 gap-4 mt-2">
        {appetizers.map((item: any, index: any) => (
          <button
            key={index}
            type="button"
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-green-600 hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            <h1 className="font-cub text-3xl">{item.name}</h1>
            <p className="mt-2">
              {item.toppings?.map((topping: any) => (
                <span className="capitalize">{topping}, </span>
              ))}
            </p>
            <p>{item.description}</p>
          </button>
        ))}
      </div>
    </div>
  );
}
