import { Link } from "react-router-dom";
import pizza from "../assets/location.png";
import chickasha from "../assets/locations/chickasha.jpg";
import xpress from "../assets/locations/express.jpg";
import lawton from "../assets/locations/lawton.jpg";
export default function Locations() {
  return (
    <div className="relative isolate overflow-hidden pt-14">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <div className="lg:col-span-2 xl:col-auto ">
            <p className='sm:text-5xl new-font uppercase text-white'>We are Open for you</p>
          <h1 className="max-w-2xl text-6xl text-ben sm:text-9xl uppercase new-font">
            <span className="text-white"> Our</span> Locations{" "}
 
          </h1>
          <p className='sm:text-6xl new-font uppercase text-white -mt-1 lg:-mt-4 '>See our Branches</p>
          </div>
          <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
            <p className="text-xl leading-8 text-zinc-100 uppercase tracking-widest font-veneer">
              We currently have three locations in Oklahoma. Our locations are in Chickasha, and Lawton. We are open from 10:45 AM to 9:00 PM.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="https://www.benjayspizzeria.com/"
                className="rounded-2xl bg-ben w-full flex items-center justify-center py-2.5 text-md  text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 uppercase new-font tracking-widest"
                target="_blank"
                rel="noreferrer"
              >
                Order Now
              </a>
              <Link
                to="/menu"
                className="text-md uppercase tracking-widest new-font leading-6 text-white w-full"
              >
                See the menu{" "}
                <span aria-hidden="true" className="ml-2">
                  →
                </span>
              </Link>
            </div>
          </div>
          <img
            src={chickasha}
            alt=""
            className="hidden lg:block mt-10 w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-12"
          />
        </div>
        <div className="sm:flex sm:items-center sm:gap-4 mt-12 sm:space-y-0 space-y-4">
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 rounded-lg w-full">
          <img
            src={chickasha}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="absolute -top-10 -z-10 sm:mr-10 block transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div className="aspect-[1097/845] w-[68.5625rem] bg-zinc-900 opacity-70" />
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-4xl uppercase tracking-wide text-white sm:text-5xl new-font">
                Chickasha
              </h2>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                405-320-5018
              </p>
              <p className="mt-6 text-4xl new-font  leading-8 text-white drop-shadow-sm ">
                208 W CHICKASHA AVE
              </p>
              <p className=" text-4xl new-font  leading-8 text-white drop-shadow-sm ">
                CHICKASHA, OK 73018
              </p>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                10:45 AM - 9:00 PM
              </p>
            </div>
          </div>
        </div>
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 rounded-lg w-full">
          <img
            src={xpress}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="absolute -top-10 -z-10 sm:mr-10 block transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div className="aspect-[1097/845] w-[68.5625rem] bg-zinc-900 opacity-70" />
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-4xl uppercase tracking-wide text-white sm:text-5xl new-font">
                Xpress - Chickasha
              </h2>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                405-222-7111
              </p>
              <p className="mt-6 text-4xl new-font  leading-8 text-white drop-shadow-sm ">
              1000 W Choctaw Ave Ste 8
              </p>
              <p className=" text-4xl new-font  leading-8 text-white drop-shadow-sm ">
                CHICKASHA, OK 73018
              </p>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                11:00 AM - 9:00 PM
              </p>
            </div>
          </div>
        </div>
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 rounded-lg w-full">
          <img
            src={lawton}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="absolute -top-10 -z-10 sm:mr-10 block transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div className="aspect-[1097/845] w-[68.5625rem] bg-zinc-900 opacity-70" />
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-4xl uppercase tracking-wide text-white sm:text-5xl new-font">
                Lawton
              </h2>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                405-369-4718
              </p>
              <p className="mt-6 text-4xl new-font  leading-8 text-white drop-shadow-sm ">
                208 W CHICKASHA AVE
              </p>
              <p className=" text-4xl new-font  leading-8 text-white drop-shadow-sm ">
                CHICKASHA, OK 73018
              </p>
              <p className="mt-6 text-4xl new-font whitespace-nowrap leading-8 text-white ">
                10:45 AM - 9:00 PM
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}
