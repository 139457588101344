import { useEffect, useState } from "react";
import texture from "../assets/texture.jpeg";
import meaty from "../assets/pizzas/mrmeaty.png";
import bbq from "../assets/pizzas/bbq.png";
import cheeseburger from "../assets/pizzas/cheesburger.png";
import hawaiin from "../assets/pizzas/hawaiin.png";
import jalapenoPopper from "../assets/pizzas/jalapenopopper.png";
import menuItems from "../assets/json/menu.json";
import veggie from "../assets/pizzas/veggie.png";
import supreme from "../assets/pizzas/supreme.png";
import cheese from "../assets/pizzas/cheese.png";
import wheninrome from "../assets/pizzas/wheninrome.png";
import margherita from "../assets/pizzas/margherita.png";
import wings from "../assets/appetizers/wings.png";
import cheesefries from "../assets/appetizers/cheesefries.png";
import hotdip from "../assets/appetizers/hotdip.png";
import mozzerellasticks from "../assets/appetizers/mozzarellasticks.png";
import friedpickles from "../assets/appetizers/friedpickles.png";
import queso from "../assets/appetizers/queso.png";
import nachos from "../assets/appetizers/nachos.png";
import breadsticks from "../assets/appetizers/breadsticks.png";
import macandcheesebites from "../assets/appetizers/macandcheesebites.png";
import beercheese from "../assets/appetizers/beercheese.png";
import chef from "../assets/salads/chef.png";
import benandjays from "../assets/salads/benandjays.png";
import cobb from "../assets/salads/cobb.png";
import house from "../assets/salads/house.png";
import grilled from "../assets/salads/grilled.png";
import greek from "../assets/salads/greek.png";
import calzone from "../assets/fixins/calzone.png";
import stromboli from "../assets/fixins/stomboli.png";
import pizzarolls from "../assets/fixins/pizzarolls.png";
import cheesybread from "../assets/fixins/cheesybread.png";
import blt from "../assets/sammyz/blt.png";
import club from "../assets/sammyz/club.png";
import meatball from "../assets/sammyz/meatball.png";
import sammyplaceholder from "../assets/sammyz/placeholder.png";
import pastaplaceholder from "../assets/pastas/pastaplaceholder.png";

const navigationInit = [
  { id: 0, name: "Pizza", href: "#", current: true },
  { id: 1, name: "Appetizers", href: "#", current: false },
  { id: 2, name: "Salads", href: "#", current: false },
  { id: 3, name: "Fixin's", href: "#", current: false },
  { id: 4, name: "Sammyz", href: "#", current: false },
  { id: 5, name: "Pasta", href: "#", current: false },
  { id: 6, name: "Beverages", href: "#", current: false },
];
export default function NewMenu({branch}: any) {
  const [navigation, setNavigation] = useState(navigationInit);
  const [pizzas, setPizzas] = useState<any[]>([]);
  const [appetizers, setAppetizers] = useState<any[]>([]);
  const [salads, setSalads] = useState<any[]>([]);
  const [fixins, setFixins] = useState<any[]>([]);
  const [sammyz, setSammyz] = useState<any[]>([]);
  const [pastas, setPastas] = useState<any[]>([]);

  const pizzaImages = [
    meaty,
    hawaiin,
    veggie,
    supreme,
    wheninrome,
    meaty,
    bbq,
    margherita,
    jalapenoPopper,
    cheeseburger,
    bbq,
    cheese,
  ];

  const appetizersImages = [
    wings,
    cheesefries,
    hotdip,
    mozzerellasticks,
    friedpickles,
    queso,
    breadsticks,
    macandcheesebites,
    beercheese,
    nachos,
  ];

  const saladsImages = [chef, benandjays, cobb, house, grilled, greek];

  const fixinImages = [calzone, stromboli, pizzarolls, cheesybread];

  const sammyzImages = [blt, club, meatball, sammyplaceholder, sammyplaceholder, sammyplaceholder, sammyplaceholder, sammyplaceholder];

  useEffect(() => {
    setPizzas(menuItems?.pizzas);
    setAppetizers(menuItems?.appetizers);
    setSalads(menuItems?.salads);
    setFixins(menuItems?.fixins);
    setSammyz(menuItems?.sammys);
    setPastas(menuItems?.pastas);
  }, []);

  const handleGoToOrder = () => {
    //target blank opens the link in a new tab
    if (branch === "Chickasha") {
    window.open("https://www.benjayspizzeria.com/", "_blank");
    } else if (branch === "Lawton") {
    window.open("https://www.benjayslawton.com/", "_blank");
    } else {
    window.open("https://www.benjaysexpress.com/", "_blank");
    }
  };

  const handleMakeCurrent = (id: number) => {
    const newNav = navigation.map((item) => {
      if (item.id === id) {
        return { ...item, current: true };
      }
      return { ...item, current: false };
    });
    setNavigation(newNav);
  };

  return (
    <div className="bg-gray-100 min-h-screen h-full relative isolate overflow-hidden -mx-12">
      <img
        src={texture}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover"
      />

      <div className="hidden lg:block mt-8">
        <header className="bg-zinc-900 ">
          <nav
            className="mx-auto flex max-w-full items-center justify-between p-6 lg:px-12"
            aria-label="Global"
          >
            {navigation.map((item) => (
              <button
                key={item.name}
                className={`${
                  item.current
                    ? "border-ben text-ben"
                    : "border-transparent text-white hover:text-ben hover:border-ben"
                } text-lg new-font uppercase font-semibold tracking-widest border-b `}
                onClick={() => handleMakeCurrent(item.id)}
              >
                {item.name}
              </button>
            ))}
          </nav>
        </header>
      </div>
      {navigation[0]?.current && (
        <div className="mt-12 grid md:grid-cols-3 grid-cols-1 gap-4 lg:px-12 px-4 pb-12">
          {pizzas?.map((pizza, index) => (
            <div>
              <div className="rounded-2xl bg-zinc-900 shadow relative mt-24 px-8">
                <img
                  className="inline-block h-64 w-64 mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
                  src={pizzaImages[pizza.img || 0]}
                  alt=""
                />
                <div className="px-4 pt-44 pb-8">
                  <div className="text-ben new-font text-center text-4xl xl:text-6xl uppercase mt-4">
                    {pizza.name}
                  </div>
                  <p className="text-white text-center mt-4">
                    {pizza.toppings?.map((top: any) => {
                      return (
                        <span className="uppercase font-cub tracking-wider">
                          {top},{" "}
                        </span>
                      );
                    })}
                  </p>
                  <div className="flex items-center justify-center gap-8 new-font uppercase tracking-wide text-xl">
                    <div>
                      <p className="text-white text-center mt-4">10" Small</p>
                      <p className="text-center  text-ben">
                        ${pizza.sizes?.small}.00
                      </p>
                    </div>

                    <div>
                      <p className="text-white text-center mt-4">14" Medium</p>
                      <p className="text-center  text-ben">
                        ${pizza.sizes?.medium}.00
                      </p>
                    </div>
                    <div>
                      <p className="text-white text-center mt-4">18" Large</p>
                      <p className="text-center  text-ben">
                        ${pizza.sizes?.large}.00
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      className="rounded-lg bg-ben text-white w-full py-2 mt-4 new-font tracking-widest uppercase"
                      onClick={handleGoToOrder}
                    >
                      Order Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {navigation[1]?.current && (
        <div className="mt-12 grid md:grid-cols-3 grid-cols-1 gap-4 lg:px-12 px-4 pb-12">
          {appetizers?.map((tizer, index) => (
            <div>
              <div className="rounded-2xl bg-zinc-900 shadow relative mt-24 px-8">
                <img
                  className="inline-block h-64 w-64 object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
                  src={appetizersImages[tizer.img || 0]}
                  alt=""
                />
                <div className="px-4 pt-44 pb-8">
                  <div className="text-ben new-font text-center text-4xl xl:text-6xl uppercase mt-4">
                    {tizer.name}
                  </div>
                  <p className="text-white text-center mt-4">
                    {tizer?.styles?.map((top: any) => {
                      return (
                        <span className="uppercase font-cub tracking-wider">
                          {top},{" "}
                        </span>
                      );
                    })}
                  </p>
                  <div className="flex items-center justify-center gap-8 new-font uppercase tracking-wide text-xl">
                    <div>
                      <p className="text-white text-center mt-4">
                        {tizer.prices?.large ? "Small" : "Appetizer"}
                      </p>
                      <p className="text-center  text-ben">
                        ${tizer.prices?.small}.00
                      </p>
                    </div>
                    {tizer.prices?.large && (
                      <div>
                        <p className="text-white text-center mt-4">Large</p>
                        <p className="text-center  text-ben">
                          ${tizer.prices?.large}.00
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <button
                      className="rounded-lg bg-ben text-white w-full py-2 mt-4 new-font tracking-widest uppercase"
                      onClick={handleGoToOrder}
                    >
                      Order Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {navigation[2]?.current && (
        <div className="mt-12 grid md:grid-cols-3 grid-cols-1 gap-4 lg:px-12 px-4 pb-12">
          {salads?.map((green) => (
            <div>
              <div className="rounded-2xl bg-zinc-900 shadow relative mt-24 px-8">
                <img
                  className="inline-block h-64 w-64 object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
                  src={saladsImages[green.img || 0]}
                  alt=""
                />
                <div className="px-4 pt-44 pb-8">
                  <div className="text-ben new-font text-center text-4xl xl:text-6xl uppercase mt-4">
                    {green.name}
                  </div>
                  <p className="text-white text-center mt-4 uppercase font-cub tracking-wider">
                    {green?.description}
                  </p>
                  <div className="flex items-center justify-center gap-8 new-font uppercase tracking-wide text-xl">
                    <div>
                      <p className="text-white text-center mt-4">Half</p>
                      <p className="text-center  text-ben">
                        ${green?.prices?.half}.00
                      </p>
                    </div>

                    <div>
                      <p className="text-white text-center mt-4">Full</p>
                      <p className="text-center  text-ben">
                        ${green?.prices?.full}.00
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      className="rounded-lg bg-ben text-white w-full py-2 mt-4 new-font tracking-widest uppercase"
                      onClick={handleGoToOrder}
                    >
                      Order Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
       {navigation[3]?.current && (
        <div className="mt-12 grid md:grid-cols-3 grid-cols-1 gap-4 lg:px-12 px-4 pb-12">
          {fixins?.map((fix) => (
            <div>
              <div className="rounded-2xl bg-zinc-900 shadow relative mt-24 px-8">
                <img
                  className="inline-block h-64 w-64 object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
                  src={fixinImages[fix.img || 0]}
                  alt=""
                />
                <div className="px-4 pt-44 pb-8">
                  <div className="text-ben new-font text-center text-4xl xl:text-6xl uppercase mt-4">
                    {fix.name}
                  </div>
                  <p className="text-white text-center mt-4 uppercase font-cub tracking-wider">
                    {fix?.description}
                  </p>
                  <div className="flex items-center justify-center gap-8 new-font uppercase tracking-wide text-xl">
                    <div>
                      <p className="text-white text-center mt-4">Item</p>
                      <p className="text-center  text-ben">
                        ${fix?.price}.00
                      </p>
                    </div>

                   
                  </div>
                  <div>
                    <button
                      className="rounded-lg bg-ben text-white w-full py-2 mt-4 new-font tracking-widest uppercase"
                      onClick={handleGoToOrder}
                    >
                      Order Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
       {navigation[4]?.current && (
        <div className="mt-12 grid md:grid-cols-3 grid-cols-1 gap-4 lg:px-12 px-4 pb-12">
          {sammyz?.map((sam) => (
            <div>
              <div className="rounded-2xl bg-zinc-900 shadow relative mt-24 px-8">
                <img
                  className="inline-block h-64 w-64 object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
                  src={sammyzImages[sam.img || 0]}
                  alt=""
                />
                <div className="px-4 pt-44 pb-8">
                  <div className="text-ben new-font text-center text-4xl xl:text-6xl uppercase mt-4">
                    {sam.name}
                  </div>
                  <p className="text-white text-center mt-4 uppercase font-cub tracking-wider">
                    {sam?.description}
                  </p>
                  <div className="flex items-center justify-center gap-8 new-font uppercase tracking-wide text-xl">
                    <div>
                      <p className="text-white text-center mt-4">Sammy</p>
                      <p className="text-center  text-ben">
                        ${sam?.price}.00
                      </p>
                    </div>

                   
                  </div>
                  <div>
                    <button
                      className="rounded-lg bg-ben text-white w-full py-2 mt-4 new-font tracking-widest uppercase"
                      onClick={handleGoToOrder}
                    >
                      Order Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
       {navigation[5]?.current && (
        <div className="mt-12 grid md:grid-cols-3 grid-cols-1 gap-4 lg:px-12 px-4 pb-12">
          {pastas?.map((past) => (
            <div>
              <div className="rounded-2xl bg-zinc-900 shadow relative mt-24 px-8">
                <img
                  className="inline-block h-64 w-64 object-cover object-center mx-auto rounded-full absolute left-0 right-0 -top-20 -mt-3 bg-white p-1"
                  src={pastaplaceholder}
                  alt=""
                />
                <div className="px-4 pt-44 pb-8">
                  <div className="text-ben new-font text-center text-4xl xl:text-6xl uppercase mt-4">
                    {past.name}
                  </div>
                  <p className="text-white text-center mt-4 uppercase font-cub tracking-wider">
                    {past?.description}
                  </p>
                  <div className="flex items-center justify-center gap-8 new-font uppercase tracking-wide text-xl">
                    <div>
                      <p className="text-white text-center mt-4">Half</p>
                      <p className="text-center  text-ben">
                        ${past?.prices?.half}.00
                      </p>
                    </div>

                    <div>
                      <p className="text-white text-center mt-4">Full</p>
                      <p className="text-center  text-ben">
                        ${past?.prices?.full}.00
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      className="rounded-lg bg-ben text-white w-full py-2 mt-4 new-font tracking-widest uppercase"
                      onClick={handleGoToOrder}
                    >
                      Order Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
