import React from 'react'
import Hero from '../components/Hero'
import SwiperMenu from '../components/SwiperMenu'
import Quality from '../components/Quality'

export default function Home() {
  return (
    <div>
        <Hero />
        <SwiperMenu />
        <Quality />
    </div>
  )
}
