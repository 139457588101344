import { Route, Routes } from "react-router-dom";
import Chickasha from "./pages/Chickasha";
import Menu from "./pages/Menu";
import HomeDashboard from "./components/HomeDashboard";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import NewMenu from "./pages/NewMenu";
import OrderOnline from "./pages/OrderOnline";
import ComingSoon from "./pages/ComingSoon";
import Locations from "./pages/Locations";
import OrderCatering from "./pages/OrderCatering";
import { useEffect, useState } from "react";
import LocationSet from "./components/LocationSet";
const userNavigationInit = [
  {
    id: 0,
    name: "Chickasha",
    selected: true,
    href: "#",
    phone: "405-320-5018",
    inStock: true,
  },
  {
    id: 1,
    name: "Chickasha - Xpress",
    selected: false,
    href: "#",
    phone: "405-222-7111",
    inStock: true,
  },
  {
    id: 2,
    name: "Lawton",
    selected: false,
    href: "#",
    phone: "580-357-6800",
    inStock: true,
  },
];

function App() {
  const [userNavigation, setUserNavigation] = useState(userNavigationInit);
  const [currentPhone, setCurrentPhone] = useState("405-320-5018");
  const [mem, setMem] = useState(userNavigation[2]);
  const [branch, setBranch] = useState("Chickasha");

  const handleCurrentUserNav = (id: number) => {
    const newNav = userNavigation.map((nav) => {
      if (nav.id === id) {
        setCurrentPhone(nav.phone);
        setBranch(nav.name);
        return { ...nav, selected: true };
      } else {
        return { ...nav, selected: false };
      }
    });
    setUserNavigation(newNav);
  };

  const hanldeSetLocations = () => {
    const isLocationSet = localStorage.getItem("location");
    if (isLocationSet === "true") {
      return;
    } else {
      localStorage.setItem("location", "true");
      setCurrentPhone(mem.phone);
      setBranch(mem.name);
    }
  };

  useEffect(() => {
    hanldeSetLocations();
  }, [mem]);

  return (
    <div>
      <LocationSet mem={mem} setMem={setMem} userNavigation={userNavigation} />
      <HomeDashboard
        currentPhone={currentPhone}
        userNavigation={userNavigation}
        handleCurrentUserNav={handleCurrentUserNav}
        branch={branch}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/chickasha" element={<Chickasha />} />
          <Route path="/new-order" element={<Menu />} />
          <Route path="catering-menu" element={<ComingSoon />} />
          <Route path="order-catering" element={<OrderCatering />} />
          <Route path="locations" element={<Locations />} />
          <Route path="/menu" element={<NewMenu branch={branch} />} />
          <Route path="/about" element={<AboutUs />} />
          <Route
            path="/order-online"
            element={<OrderOnline branch={branch} />}
          />
        </Routes>
      </HomeDashboard>
    </div>
  );
}

export default App;
