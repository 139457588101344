import React from "react";

export default function OrderCatering() {
  return (
    <div className="min-h-screen h-full flex items-center justify-center px-4">
      <div className="-mt-96 text-center ">
        <h1 className="sm:text-9xl text-4xl new-font uppercase text-white">
          Order <span className="text-ben">Catering</span>
        </h1>
        <p className="font-cub text-2xl tracking-widest">
          For all inquiries, please call us at{" "}
          <a href="tel:405-224-3366" className="text-ben block">
            405-224-3366
          </a>{" "}
          or email us at{" "}
          <a href="mailto:support@benanjays.com" className="text-ben block">
            {" "}
            support@benanjays.com{" "}
          </a>
        </p>
      </div>
    </div>
  );
}
